/**
 * @author Sergey Tumarkin https://tumarkin.me
 */
import { 
    Alert,
 } from 'react-bootstrap';
import { useState, useEffect, useCallback, useRef } from 'react';



export default function ReestrAdd ( props ){
    useEffect(() => {

    },[])
    

    return(
        <>
            <h1>Реестр компаний</h1>

            <div className="row">
                <div className="col-xl-12 col-md-6">
                        <Alert 
                            variant="secondary" 
                            className='mx-4'
                        >
                            Команда сервиса Приемка Про запустили первый федеральный реестр, включающий  <a href="https://priemka-pro.ru/reestr/" target="_blank" >компании по приемке квартир в новостройке</a>, оказывающих такие услуги в крупнейших городах России. Для добавления своей компании, заполните заявку ниже
                        </Alert>
                </div>
            </div>
            
            <iframe 
                src="https://docs.google.com/forms/d/e/1FAIpQLSd3xPZDB9aXBGlzU-ahRk1V8uHXRho2auxpIXS4aY8Iarc5qg/viewform?embedded=true" 
                width="640" 
                height="3200" 
                frameborder="0" 
                marginheight="0" 
                marginwidth="0"
            >
                Loading…
            </iframe>
            

        </>

    )
}