import { Link } from 'react-router-dom';

export default function Header ( props ){
    const sidenavToggle = (val) => props.sidenavToggle(val)
    const { account } = props

    const queryParams = new URLSearchParams(window.location.search);
    const webview = queryParams.has('webview')
    if (webview) return null


    return (
        <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
                <div className="nav">
                    <div className="sb-sidenav-menu-heading"></div>

                    <Link className="nav-link" to="/forms">
                        <div className="sb-nav-link-icon"><i className="far fa-square-check"></i></div>
                        Готовые приёмки
                    </Link>
                    <div className="sb-sidenav-menu-heading">Настройки</div>
                    <Link className="nav-link" to="/users">
                        <div className="sb-nav-link-icon"><i className="fas fa-users"></i></div>
                        Пользователи
                    </Link>
                    <Link className="nav-link" to="/settings-form">
                        <div className="sb-nav-link-icon"><i className="fas fa-sliders"></i></div>
                        Справочник проверок
                    </Link>
                    <Link className="nav-link" to="/settings-report">
                        <div className="sb-nav-link-icon"><i className="fas fa-file-invoice"></i></div>
                        Настройка отчета
                    </Link>
                    <div className="sb-sidenav-menu-heading">Оплата</div>
                    <Link className="nav-link" to="/plans">
                        <div className="sb-nav-link-icon"><i className="fas fa-credit-card"></i></div>
                        Тариф и оплата
                    </Link>
                    
                    <div className="sb-sidenav-menu-heading">Помощь</div>
                    {/* <Link className="nav-link" to="/help">
                        <div className="sb-nav-link-icon"><i className="fas fa-circle-info"></i></div>
                        Вопросы и ответы
                    </Link> */}
                    <Link className="nav-link" to="https://priemka-pro.ru/app/" target='_blank'>
                        <div className="sb-nav-link-icon"><i className="fas fa-circle-info"></i></div>
                        Моб. приложения
                    </Link>
                    <Link className="nav-link" to="https://t.me/priemka_pro" target='_blank'>
                            <div className="sb-nav-link-icon"><i className="fas fa-circle-info"></i></div>
                            Канал о проекте
                    </Link>
                    <Link className="nav-link" to="https://t.me/stumarkin" target='_blank'>
                            <div className="sb-nav-link-icon"><i className="fas fa-circle-info"></i></div>
                            Чат поддержки
                    </Link>
                    
                    <div className="sb-sidenav-menu-heading">Реестр команий</div>
                    <Link className="nav-link" to="/reestr" target='_blank'>
                            <div className="sb-nav-link-icon"><i className="fas fa-circle-info"></i></div>
                            Добавить компанию в&nbsp;реестр
                    </Link>

                    
                    {/* <Link className="nav-link" to="/users">
                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                        Оплата
                    </Link>
                    <div className="sb-sidenav-menu-heading">Interface</div>
                    <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                        <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                        Layouts
                        <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                    </a>
                    <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <a className="nav-link" href="layout-static.html">Static Navigation</a>
                            <a className="nav-link" href="layout-sidenav-light.html">Light Sidenav</a>
                        </nav>
                    </div>
                    <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
                        <div className="sb-nav-link-icon"><i className="fas fa-book-open"></i></div>
                        Pages
                        <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                    </a>
                    <div className="collapse" id="collapsePages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseAuth" aria-expanded="false" aria-controls="pagesCollapseAuth">
                                Authentication
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            <div className="collapse" id="pagesCollapseAuth" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <a className="nav-link" href="login.html">Login</a>
                                    <a className="nav-link" href="register.html">Register</a>
                                    <a className="nav-link" href="password.html">Forgot Password</a>
                                </nav>
                            </div>
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseError" aria-expanded="false" aria-controls="pagesCollapseError">
                                Error
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            <div className="collapse" id="pagesCollapseError" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <a className="nav-link" href="401.html">401 Page</a>
                                    <a className="nav-link" href="404.html">404 Page</a>
                                    <a className="nav-link" href="500.html">500 Page</a>
                                </nav>
                            </div>
                        </nav>
                    </div>
                    <div className="sb-sidenav-menu-heading">Addons</div>
                    <a className="nav-link" href="charts.html">
                        <div className="sb-nav-link-icon"><i className="fas fa-chart-area"></i></div>
                        Charts
                    </a>
                    <a className="nav-link" href="tables.html">
                        <div className="sb-nav-link-icon"><i className="fas fa-table"></i></div>
                        Tables
                    </a> */}
                </div>
            </div>
            <div className="sb-sidenav-footer">
                <div className="small">&nbsp;</div>
                <div className="small">&nbsp;</div>
            </div>
        </nav>
    )
}